<template>
  <section id="dashboard-ecommerce">
    <b-row class="content-header">
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              System Statistics
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item v-if="false">
                  Data range:
                  <flat-pickr
                    class="form-control"
                    :config="{ mode: 'range'}"
                  />
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- General Stats -->
    <b-row>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="FileIcon"
          :statistic="stats.documentsSummary.total_documents | numeral('0,0')"
          statistic-title="Total Documents Uploaded"
        />
      </b-col>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          :statistic="stats.documentsSummary.total_documents_storage | prettyBytes"
          statistic-title="Total Storage Used"
        />
      </b-col>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="FilePlusIcon"
          color="danger"
          :statistic="stats.documentsSummary.new_files_today | numeral('0,0')"
          statistic-title="New Files Today"
        />
      </b-col>
    </b-row>

    <!-- stats by states and plan owners -->
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card class="">
          <b-tabs nav-class="mb-3">
            <b-tab
              title="Total Documents by State"
              active
            >
              <documents-total-by-state :data="stats.documentsByState" />
            </b-tab>
            <b-tab
              title="Total Documents by Plan Owner"
            >
              <documents-total-by-plan-owner />
            </b-tab>
            <b-tab
              title="Total Documents by Plan Type"
            >
              <documents-total-by-plan-type />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col
        xl="12"
        md="12"
      />
    </b-row>

    <documents-by-status />

    <b-row class="match-height">
      <b-col
        lg="4"
        md="6"
      >
        <documents-donut />
      </b-col>
      <b-col
        lg="8"
        md="6"
      >
        <documents-timeline-chart />
      </b-col>
    </b-row>

    <user-stats />

    <b-row
      v-if="false"
      class="match-height"
    >
      <b-col lg="4">
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>

      <b-col lg="8">
        <timeline />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BTabs, BTab, BBreadcrumb,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import store from '@/store'
import statStoreModule from '@/views/eplan/dashboard/statStoreModule'
import router from '@/router'
import DocumentsByStatus from '@/views/eplan/dashboard/DocumentsByStatus.vue'
import UserStats from '@/views/eplan/dashboard/UserStats.vue'
import DocumentsTotalByPlanType from './DocumentsTotalByPlanType.vue'
import DocumentsTotalByState from './DocumentsTotalByState.vue'
import Timeline from './Timeline.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import DocumentsTimelineChart from './DocumentsTimelineChart.vue'
import DocumentsDonut from './DocumentsDonut.vue'
import DocumentsTotalByPlanOwner from './DocumentsTotalByPlanOwner.vue'

export default {
  components: {
    DocumentsByStatus,
    UserStats,
    DocumentsTotalByPlanType,
    DocumentsTotalByPlanOwner,
    DocumentsDonut,
    StatisticCardHorizontal,
    DocumentsTimelineChart,
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BBreadcrumb,

    DocumentsTotalByState,
    Timeline,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      stats: {
        documentsSummary: {
          total_documents: 0,
          total_documents_storage: 0,
          new_files_today: 0,
        },
        documentsByState: [],
      },
    }
  },
  watch: {},
  destroyed() {
    // Unregister store
    if (store.hasModule('app-stat')) store.unregisterModule('app-stat')
  },
  created() {
    // Register module
    if (!store.hasModule('app-stat')) store.registerModule('app-stat', statStoreModule)

    // fetch documents summary
    store.dispatch('app-stat/fetchStat', { type: 'documents-summary' })
      .then(response => { this.stats.documentsSummary = response.data.payload })
      .catch(() => { this.stats.documentsSummary = {} })

    // fetch documents by state
    store.dispatch('app-stat/fetchStat', { type: 'documents-by-state' })
      .then(response => { this.stats.documentsByState = response.data.payload })
      .catch(() => { this.stats.documentsByState = [] })

    // data
    this.$http.get('/eplan/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  mounted() {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
