<template>
  <b-row class="match-height">
    <b-col
      lg="8"
      md="6"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>Documents Status by Plan Owner</b-card-title>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="!!series"
            type="bar"
            height="450"
            :options="chartOptions"
            :series="series"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      lg="4"
      md="6"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>Documents by Status</b-card-title>
          <b-dropdown
            v-if="false"
            text="Last 7 Days"
            variant="transparent"
            class="chart-dropdown "
            right
            no-caret
            toggle-class="p-0 mb-25"
            size="sm"
          >
            <b-dropdown-item
              v-for="day in chartData.lastDays"
              :key="day"
            >
              {{ day }}
            </b-dropdown-item>
          </b-dropdown>
        </b-card-header>

        <b-card-body>
          <!-- apex chart -->
          <vue-apex-charts
            v-if="statusPie.series"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="statusPie.chartOptions"
            :series="statusPie.series"
          />

          <!-- list group -->
          <div
            v-if="statusPie.series"
            class="pt-25"
          >
            <div
              v-for="(data,index) in statusPie.chartOptions.labels"
              :key="index"
              class="d-flex justify-content-between"
              :class="index === Object.keys(statusPie.chartOptions.labels).length - 1 ? '':'mb-1'"
            >
              <div class="series-info">
                <span :style="{color: statusPie.chartOptions.colors[index]}">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="mr-50"
                  />
                </span>
                <span class="font-weight-bolder">{{ data }}</span>
              </div>
              <span>{{ statusPie.series[index] }}</span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import statStoreModule from './statStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  data() {
    return {
      series: null,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 450,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              enabled: true,
              position: 'top', // top, center, bottom
              style: {
                fontSize: '10px',
                fontWeight: 400,
                colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val}K`
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
      },
      chartData: {
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        listData: [
          {
            icon: 'CircleIcon',
            iconColor: 'text-info',
            text: 'Draft',
            result: 890,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-warning',
            text: 'Pending',
            result: 258,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-success',
            text: 'Approved',
            result: 149,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-danger',
            text: 'Rejected',
            result: 14,
          },
        ],
      },
      statusPie: {
        // series: [890, 258, 149, 14],
        series: null,
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Draft', 'Review', 'Pending', 'Approved', 'Rejected'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.info, $themeColors.warning, $themeColors.success, $themeColors.danger],
        },
      },
    }
  },
  created() {
    // Register module
    if (!store.hasModule('app-stat')) store.registerModule('app-stat', statStoreModule)
  },
  destroyed() {
    // Unregister store
    if (store.hasModule('app-stat')) store.unregisterModule('app-stat')
  },
  mounted() {
    // fetch documents by status
    const labelLower = this.statusPie.chartOptions.labels.map(l => l.toLowerCase())
    const series = new Array(labelLower.length).fill(0)
    store
      .dispatch('app-stat/fetchReport', { type: 'document-by-status', payload: {} })
      .then(response => {
        response.data.payload.data.forEach(d => {
          series[labelLower.indexOf(d.status)] = d.count
        })
        this.statusPie.series = series
      })
      .catch()

    // fetch document status by plan owners
    store
      .dispatch('app-stat/fetchReport', { type: 'document-by-status', payload: { group_by: ['plan-owner'] } })
      .then(response => {
        const documentStatusByPlanOwnerSeries = []
        // build categories (y-axis)
        const documentStatusByPlanOwnerCategories = []
        response.data.payload.data.forEach(d => {
          if (documentStatusByPlanOwnerCategories.indexOf(d.plan_owner_name) === -1) {
            documentStatusByPlanOwnerCategories.push(d.plan_owner_name)
          }
        })
        this.chartOptions.xaxis.categories = documentStatusByPlanOwnerCategories

        // build series and data
        response.data.payload.data.forEach(d => {
          let seriesFind = documentStatusByPlanOwnerSeries.find(s => s.name === d.status)
          if (!seriesFind) {
            documentStatusByPlanOwnerSeries.push({ name: d.status, data: new Array(documentStatusByPlanOwnerCategories.length).fill(0) })
            seriesFind = documentStatusByPlanOwnerSeries.find(s => s.name === d.status)
          }
          const categoryIdx = documentStatusByPlanOwnerCategories.indexOf(d.plan_owner_name)
          seriesFind.data[categoryIdx] = d.count
        })
        this.series = documentStatusByPlanOwnerSeries
      })
      .catch()
  },
}
</script>
