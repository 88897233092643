<template>
  <b-row>
    <b-col
      v-for="(item, index) in computedStats"
      :key="index"
      xl="3"
      sm="6"
      :class="item.customClass"
    >
      <b-link :to="{name: 'document-list', query: {advancesearch: 1, 'planOwner.name': item.plan_owner_name}}">
        <b-media
          no-body
          class="mb-2"
        >
          <b-media-aside
            class="mr-2"
          >
            <b-avatar
              size="64"
              class="shadow-lg"
              :text="item.acronym"
              :variant="item.color"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ item.count | numeral('0,0') }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              {{ item.plan_owner_name }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCardText,
  BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import statStoreModule from '@/views/eplan/dashboard/statStoreModule'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'DocumentsTotalByPlanOwner',
  components: {
    BRow,
    BCol,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  data() {
    return {
      stats: [],
    }
  },
  computed: {
    computedStats() {
      return this.stats ? this.stats.map(data => ({
        color: 'primary',
        customClass: 'mb-2 mb-xl-0',
        icon: 'jps-logo.png',
        plan_owner_name: data.plan_owner_name ?? '(NULL)',
        acronym: data.plan_owner_acronym,
        count: data.count,
      })) : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule('app-stat')) store.registerModule('app-stat', statStoreModule)
  },
  destroyed() {
    // Unregister store
    if (store.hasModule('app-stat')) store.unregisterModule('app-stat')
  },
  mounted() {
    // fetch documents by plan owners
    store
      .dispatch('app-stat/fetchReport', { type: 'plan-owner-total', payload: {} })
      .then(response => {
        this.stats = response.data.payload.data
      })
      .catch()
  },
  methods: {
    avatarText,
  },
}
</script>
