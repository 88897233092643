<template>
  <b-card>
    <b-card-title class="mb-1">
      Documents by Type
    </b-card-title>
    <b-card-sub-title class="mb-2">
      Uploaded documents by file type
    </b-card-sub-title>

    <vue-apex-charts
      v-if="chartData.series.length > 0"
      type="donut"
      height="350"
      :options="chartData.chartOptions"
      :series="chartData.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'
import reportStoreModule from '@/views/eplan/reports/reportStoreModule'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      series: [85, 16, 50, 50],
      labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
      chartData: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            floating: false,
            horizontalAlign: 'center',
            fontSize: '8px',
            fontFamily: 'Poppins, Helvetica, Arial',
          },
          colors: [
            '#ffe700',
            '#FFA1A1',
            '#826bf8',
            '#00d4bd',
            '#2b9bf4',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Poppins, Helvetica, Arial',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Poppins, Helvetica, Arial',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'TOTAL',
                    // formatter(val) {
                    //   console.log(val)
                    //   return val
                    // },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  created() {
    // Register store module
    if (!store.hasModule('app-report')) store.registerModule('app-report', reportStoreModule)
  },
  destroyed() {
    // unregister store module
    if (store.hasModule('app-report')) store.unregisterModule('app-report')
  },
  mounted() {
    store
      .dispatch('app-report/fetchReport', { type: 'document-by-type' })
      .then(response => {
        const { payload } = response.data
        this.buildChartData(payload)
      })
      .catch()
      .finally()
  },
  methods: {
    buildChartData(payload) {
      const chart = {
        series: [],
        labels: [],
      }

      // build labels
      payload.data.forEach(row => {
        const mimetype = row.mimetype ?? '(unknown)'
        if (!chart.labels.find(l => l === mimetype)) {
          chart.labels.push(mimetype)
        }
      })
      chart.series = new Array(chart.labels.length).fill(0)

      // build data
      payload.data.forEach(row => {
        const mimetype = row.mimetype ?? '(unknown)'
        // add to series data
        const dataIndex = chart.labels.findIndex(d => d === mimetype)
        chart.series[dataIndex] = row.count
      })

      this.chartData.chartOptions.labels = chart.labels
      this.chartData.series = chart.series
    },
  },
}
</script>
