<template>
  <b-row>
    <b-col
      v-for="(item, index) in stats"
      :key="index"
      xl="3"
      sm="6"
      :class="item.customClass"
    >
      <b-link :to="{name: 'document-list', query: {advancesearch: 1, 'state.name': item.state_name}}">
        <b-media
          no-body
          class="mb-2"
        >
          <b-media-aside

            class="mr-2"
          >
            <b-avatar
              size="64"
              class="shadow-lg"
              :src="`/states/${item.icon}`"
              :variant="item.color"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ item.count | numeral('0,0') }}
            </h4>
            <div class="font-small-3 mb-0">
              {{ item.state_name }}
            </div>
          </b-media-body>
        </b-media>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink,
} from 'bootstrap-vue'

export default {
  name: 'DocumentsTotalByState',
  components: {
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    stats() {
      return this.data ? this.data.map(data => ({
        color: 'light-primary',
        customClass: 'mb-2 mb-xl-0',
        icon: data.state_logo ?? 'jps-logo.png',
        state_name: data.state_name ?? '(NULL)',
        count: data.count,
      })) : []
    },
  },
}
</script>
