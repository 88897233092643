<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">Documents by Day</b-card-title>
        <b-card-sub-title>Recently number of uploaded documents</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div v-if="false" class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="chartData.series.length > 0"
        type="line"
        height="400"
        :options="chartData.chartOptions"
        :series="chartData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import reportStoreModule from '@/views/eplan/reports/reportStoreModule'

export default {
  name: 'DocumentsTimelineChart',
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  data() {
    return {
      rangePicker: ['2019-05-01', '2019-05-10'],
      chartColors: {
        column: {
          series1: '#826af9',
          series2: '#d2b0ff',
          bg: '#f8d3ff',
        },
        success: {
          shade_100: '#7eefc7',
          shade_200: '#06774f',
        },
        donut: {
          series1: '#ffe700',
          series2: '#00d4bd',
          series3: '#826bf8',
          series4: '#2b9bf4',
          series5: '#FFA1A1',
        },
        area: {
          series3: '#a4f8cd',
          series2: '#60f2ca',
          series1: '#2bdac7',
        },
      },
      chartData: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ['#2b9bf4', '#00d4bd', '#826bf8', '#ffe700', '#FFA1A1', '#06774f'],
          dataLabels: {
            enabled: true,
            offsetY: -5,
            style: {
              colors: ['#eaeaea'],
            },
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: 'Upload by file types',
            align: 'left',
            fontFamily: 'Poppins, Helvetica, Arial',
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Day',
            },
          },
          yaxis: {
            title: {
              text: 'Count',
            },
            min: 0,
            // max: 40,
            logarithmic: true,
            forceNiceScale: true,
            labels: {
              fontSize: '8px',
              fontFamily: 'Poppins, Helvetica, Arial',
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            showForSingleSeries: true,
            // offsetY: -25,
            // offsetX: -5,
            labels: {
              fontSize: '8px',
              fontFamily: 'Poppins, Helvetica, Arial',
            },
          },
        },
      },
    }
  },
  created() {
    // Register store module
    if (!store.hasModule('app-report')) store.registerModule('app-report', reportStoreModule)
  },
  destroyed() {
    // unregister store module
    if (store.hasModule('app-report')) store.unregisterModule('app-report')
  },
  mounted() {
    const payload = {
      start_date: this.$dayjs().subtract(2, 'weeks').format('YYYY-MM-DD'),
      end_date: this.$dayjs().format('YYYY-MM-DD'),
      group_by: ['type'],
    }
    store
      .dispatch('app-report/fetchReport', { type: 'document-by-day', payload })
      .then(response => {
        this.buildChartData(response.data.payload)
      })
      .catch()
      .finally()
  },
  methods: {
    buildChartData(payload) {
      const chart = {
        series: [],
        categories: [],
      }

      const getDaysArray = (s, e) => { for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) { a.push(new Date(d).toISOString().split('T')[0]) } return a }

      // build categories
      chart.categories = getDaysArray(payload.request.start_date, payload.request.end_date)

      // build data
      payload.data.forEach(row => {
        const mimetype = row.mimetype ?? '(unknown)'
        // add to series data
        const dataIndex = chart.categories.findIndex(d => d === row.date)
        let series = chart.series.find(s => s.name === mimetype)
        if (!series) {
          chart.series.push({
            name: mimetype,
            data: new Array(chart.categories.length).fill(0),
          })
          series = chart.series.find(s => s.name === mimetype)
        }
        series.data[dataIndex] = row.count
      })

      this.chartData.chartOptions.xaxis.categories = chart.categories
      this.chartData.series = chart.series
    },
  },
}
</script>
