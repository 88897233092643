<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <b-card no-body>
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Logins by Day
            </b-card-title>
            <b-card-sub-title>Number of logins recently</b-card-sub-title>
          </div>
          <!--/ title and subtitle -->
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="!!lineChart.series"
            type="line"
            height="350"
            :options="lineChart.options"
            :series="lineChart.series"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
        v-if="false"
      lg="4"
      md="6"
    >
      <b-card
        class="card-transaction"
        no-body
      >
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Login Summary
            </b-card-title>
            <b-card-sub-title>Summary of login</b-card-sub-title>
          </div>
          <!--/ title and subtitle -->
          <b-dropdown
            v-if="false"
            text="Last 7 Days"
            variant="transparent"
            class="chart-dropdown "
            right
            no-caret
            toggle-class="p-0 mb-25"
            size="sm"
          >
            <b-dropdown-item
              v-for="day in chartData.lastDays"
              :key="day"
            >
              {{ day }}
            </b-dropdown-item>
          </b-dropdown>
        </b-card-header>

        <b-card-body class="mt-5">
          <div
            v-for="transaction in transactionData"
            :key="transaction.mode"
            class="transaction-item mt"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="42"
                  :variant="transaction.avatarVariant"
                >
                  <feather-icon
                    size="18"
                    :icon="transaction.avatar"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">
                  {{ transaction.mode }}
                </h6>
                <small>{{ transaction.types }}</small>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
            >
              {{ transaction.total }}
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem, BCardBody,
  BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import get from 'lodash'
import statStoreModule from './statStoreModule'

export default {
  name: 'UserStats',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    VueApexCharts,
  },
  data() {
    return {
      transactionData: [
        {
          mode: 'Login Today',
          types: 'All login today',
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          total: '12',
          deduction: false,
        },
        {
          mode: 'Paypal',
          types: 'Active Session',
          avatar: 'Currently active sessions',
          avatarVariant: 'light-danger',
          total: '6',
          deduction: false,
        },
        {
          mode: 'Total Login',
          types: 'Last 7 days',
          avatar: 'TrendingUpIcon',
          avatarVariant: 'light-success',
          total: '78',
          deduction: false,
        },
      ],
      lineChart: {
        series: null,
        options: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
              show: true,
              // tools: {
              //   download: true,
              // },
            },
          },
          dataLabels: {
            enabled: true,
            // offsetY: -5,
            style: {
              colors: ['#efefef'],
            },
            enabledOnSeries: [0],
          },
          stroke: {
            width: [1, 1, 4],
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              // logarithmic: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB',
              },
              labels: {
                style: {
                  colors: '#008FFB',
                },
              },
              title: {
                text: '# of visitors',
                style: {
                  color: '#008FFB',
                },
              },
              tooltip: {
                enabled: true,
              },
            },
            {
              seriesName: 'TOTAL',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#FEB019',
              },
              labels: {
                style: {
                  colors: '#FEB019',
                },
              },
              title: {
                text: 'TOTAL',
                style: {
                  color: '#FEB019',
                },
              },
            },
          ],
          legend: {
            horizontalAlign: 'center',
            // offsetX: 40,
            offsetY: 50,
            position: 'right',
          },
        },
      },
    }
  },
  created() {
    // Register module
    if (!store.hasModule('app-stat')) store.registerModule('app-stat', statStoreModule)
  },
  mounted() {
    // fetch document status by plan owners
    const payload = {
      start_date: this.$dayjs().subtract(13, 'day').format('YYYY-MM-DD'),
      end_date: this.$dayjs().format('YYYY-MM-DD'),
      group_by: ['role'],
    }
    store
      .dispatch('app-stat/fetchReport', { type: 'login-by-day', payload })
      .then(response => {
        this.buildLineChartData(response.data.payload)
      })
      .catch()
  },
  methods: {
    buildLineChartData(payload) {
      const chart = {
        series: [{
          name: 'TOTAL',
          type: 'line',
          data: [20, 29, 37, 36, 44, 45, 50, 58, 45, 50, 58, 36, 44, 45],
        }],
        categories: [],
      }

      const getDaysArray = (s, e) => { for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) { a.push(new Date(d).toISOString().split('T')[0]) } return a }

      // build categories
      chart.categories = getDaysArray(payload.request.start_date, payload.request.end_date)

      // build series data
      payload.data.forEach(row => {
        const roleName = row.role_name ?? '(unknown)'
        // skip superadmin, systemadmin
        if (!['superadmin', 'systemadmin'].includes(roleName)) {
          // add to series data
          const dataIndex = chart.categories.findIndex(d => d === row.date)
          let series = chart.series.find(s => s.name === roleName)
          if (!series) {
            chart.series.push({
              name: roleName,
              type: 'column',
              data: new Array(chart.categories.length).fill(0),
            })
            series = chart.series.find(s => s.name === roleName)
          }
          series.data[dataIndex] = row.count
        }
      })

      // calculate total
      chart.series[0].data = new Array(chart.categories.length).fill(0)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < chart.categories.length; i++) {
        chart.series[0].data[i] = (chart.series[1] ? chart.series[1].data[i] : 0)
            + (chart.series[2] ? chart.series[2].data[i] : 0)
            + (chart.series[3] ? chart.series[3].data[i] : 0)
      }

      // add to chart setting
      this.lineChart.options.xaxis.categories = chart.categories
      this.lineChart.series = chart.series
    },
  },
}
</script>
