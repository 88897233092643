import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStat(ctx, { type, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/stats/${type}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReport(ctx, { type, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/reports/${type}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
